import MarketingService from "@/libs/marketing.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAgency(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getAgencyLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAgencyDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        MarketingService.getAgencyDetail(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    addAgency(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.addAgency(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateAgency(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.updateAgency(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAgencyRadio(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getAgencyRadioLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchListUserAgency(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getListUserAgency(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchReportAgency(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getReportAgency(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAgencyPaymentHistory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getAgencyPaymentHistory(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAgencyWinLoss(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getAgencyAccountingWinLoss(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    fetchAgencyWinLossByUser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getAgencyAccountingWinLossByUser(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    fetchLevel(ctx) {
      return new Promise((resolve, reject) => {
        MarketingService.getLevel()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addAgencyHistory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.addAgencyHistory(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAgencyCommission(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getAgencyCommissions(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    exportAgencyCommission(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.exportAgencyCommissions(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    fetchAgencyCommissionSetting(ctx) {
      return new Promise((resolve, reject) => {
        MarketingService.getAgencyCommissionSetting()
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    updateAgencyRevenue(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.updateAgencyRevenue(queryParams)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
      });
    },
    fetchSubAgency(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getSubAgencyLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchListSocialNetwork(ctx) {
      return new Promise((resolve, reject) => {
        MarketingService.getListSocialNetwork()
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchLoginLog(ctx, id) {
      return new Promise((resolve, reject) => {
        MarketingService.getAgencyLoginLog(id)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserUpgradeLevel(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getUserUpgradeLevel(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchUserNewDepositReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        MarketingService.getUserNewDepositReport(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
