import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class MarketingService {
  getAgencyLists(queryParams) {
    const pars = {
      username: queryParams.username,
      code: queryParams.code,
      id: queryParams.id,
      email: queryParams.email,
      // name: queryParams.fullname,
      phone: queryParams.phone,
      discount: queryParams.discount,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      status: queryParams.status,
      commission_type: queryParams.commission_type,
      agency_type: queryParams.agency_type, 
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance.get("/api/agency/index", {
      headers: authHeader(),
      params: pars,
    });
  }

  addAgency(postData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("email", postData.email);
    data.append("username", postData.username);
    data.append("phone", postData.phone);
    data.append("password", postData.password);
    data.append("discount", postData.discount);
    data.append("discount", postData.discount);
    data.append("commission_type", postData.commissionType);
    data.append("updated_by", postData.updated_by);

    return instance.post("/api/agency/create", data, { headers: authHeader() });
  }

  // getLocalTransferType() {
  // 	return instance.get('/api/local-transfer-type/index', { headers: authHeader() })
  //             .then(response => {
  //                 return response.data
  //             })
  // }

  updateAgency(status) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", status.id);
    data.append("email", status.email);
    data.append("username", status.username);
    data.append("phone", status.phone);
    data.append("password", status.password);
    data.append("discount", status.discount);
    data.append("status", status.status);
    data.append("commission_type", status.commissionType);

    return instance.post("/api/agency/update", data, {
      headers: authHeader(),
    });
  }

  getAgencyRadioLists(queryParams) {
    const pars = {
      // username: queryParams.username,
      // id: queryParams.id,
      // email: queryParams.email,
      // name: queryParams.fullname,
      // phone: queryParams.phone,
      // discount: queryParams.discount,
      // fromDate: queryParams.fromdate,
      // toDate: queryParams.todate,
      // status: queryParams.stat,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance.get("/api/rebate-ratio/index", {
      headers: authHeader(),
      params: pars,
    });
  }
  getAgencyDetail(userId) {
    return instance.get(`/api/agency/detail?id=${userId}`, {
      headers: authHeader(),
    });
  }
  getListUserAgency(queryParams) {
    const pars = {
      username: queryParams.username,
      agency_id: queryParams.agency_id,
      email: queryParams.email,
      phone: queryParams.phone,
      discount: queryParams.discount,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      status: queryParams.status,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance.get(`/api/agency/user-list`, {
      headers: authHeader(),
      params: pars,
    });
  }
  getReportAgency(queryParams) {
    const pars = {
      agency_id: queryParams.agency_id,
      fromDate: queryParams.fromdate,
      toDate: queryParams.todate,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance.get(`/api/agency/revenue`, {
      headers: authHeader(),
      params: pars,
    });
    // .then((response) => {
    //   console.log(5555,response);
    //   const datas = {
    //     deposits: response.data.data,
    //     total: response.data.count,
    //     pageSize: queryParams.page == 1 ? response.data.data?.length : 25,
    //   };
    //   return datas;
    // });
  }
  getAgencyPaymentHistory(queryParams) {
    const pars = {
      agency_id: queryParams.agency_id,
      ref: queryParams.ref,
      bills: queryParams.logo,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      pageNumber: queryParams.page,
      //   status: queryParams.status,
    };
    return instance
      .get("/api/agency/payment-history", {
        headers: authHeader(),
        params: pars,
      })
      .then((response) => {
        const datas = {
          paymentHistory: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data?.data?.length : 25,
        };
        return datas;
      });
  }

  getAgencyAccountingWinLoss(queryParams) {
    const pars = {
      agencyId: queryParams.agencyId,
      // ref: queryParams.ref,
      // productId: queryParams.productId,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      // pageNumber: queryParams.page,
      //   status: queryParams.status,
    };
    return instance
        .get("/api/agency/accounting/win-loss", {
          headers: authHeader(),
          params: pars,
        })
        .then((response) => {
          return {
            sportTeam: response.data.data,
            total: response.data.count,
            pageSize: queryParams.page === 1 ? response.data?.data?.length : 25,
          };
        });
  }

  getAgencyAccountingWinLossByUser(queryParams) {
    const pars = {
      agencyCode: queryParams.agencyCode,
      productId: queryParams.productId,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate
    };
    return instance.get("/api/agency/accounting/win-loss-by-user", {
      headers: authHeader(),
      params: pars,
    });
  }

  getLevel() {
    return instance.get("/api/Level/index", { headers: authHeader() });
  }
  addAgencyHistory(postData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("agency_id", postData.agency_id);
    data.append("amount", postData.amount * 1000);
    data.append("note", postData.note);
    data.append("month", postData.month);
    data.append("year", postData.year);
    if (postData.banners.length > 0) {
      for (let i = 0; i < postData.banners.length; i++) {
        data.append("bills[]", postData.banners[i]);
      }
    }
    return instance.post("/api/agency/payment-create", data, {
      headers: authHeader(),
    });
  }

  getAgencyCommissions(queryParams) {
    const pars = {
      agency_code: queryParams.agencyCode,
      agency_username: queryParams.agencyName,
      month: queryParams.month,
      year: queryParams.year,
      pageNumber: queryParams.page,
    };
    return instance
        .get("/api/agency/comissions/index", {
          headers: authHeader(),
          params: pars,
        })
        .then((response) => {
          return {
            result: response.data.data,
            total: response.data.count,
            pageSize: queryParams.page === 1 ? response.data?.data?.length : 25,
          };
        });
  }

  exportAgencyCommissions(queryParams) {
    const pars = {
      agency_code: queryParams.agencyCode,
      agency_username: queryParams.agencyName,
      month: queryParams.month,
      year: queryParams.year
    };
    const data = {};
    return instance
        .post("/api/agency/comissions/export", data,{
          headers: authHeader(),
          params: pars,
        });
  }

  getAgencyCommissionSetting() {
    return instance
        .get("/api/agency-commission-setting/index", {
          headers: authHeader(),
        })
        .then((response) => {
          return response;
        });
  }

  updateAgencyRevenue(request) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", request.id);
    data.append("from", request.from);
    data.append("to", request.to);
    data.append("min_user", request.min_user);
    data.append("discount", request.discount);

    return instance.post("/api/agency-commission-setting/update", data, {
      headers: authHeader(),
    });
  }
  
  getSubAgencyLists(queryParams) {
    const pars = {
      username: queryParams.username,
      parentId: queryParams.parentId,
      email: queryParams.email,
      phone: queryParams.phone,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      status: queryParams.status,
      pageNumber: queryParams.page,
    };
    return instance.get("/api/sub-agency/list", {
      headers: authHeader(),
      params: pars,
    });
  }

  getListSocialNetwork() {
    return instance.get("/api/social-network/index", {
      headers: authHeader(),
    });
  }

  getAgencyLoginLog(queryParams) {
    const pars = {
      ip: queryParams.ip,
      platform: queryParams.platform,
      device: queryParams.device,
      device_model: queryParams.device_model,
      pageNumber: queryParams.page,
      pageSize: queryParams.perPage,
    };
    return instance
      .get("/api/agency/login-log/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          loginLogs: response.data.data,
          total: response.data.count,
        };
        return datas;
      });
  }

  getUserUpgradeLevel(queryParams) {
    const pars = {
      pageNumber: queryParams.pageNumber,
    };
    return instance.get(`/api/user-upgrade-level/index`, {
      headers: authHeader(),
      params: pars
    });
  }

  getUserNewDepositReport(queryParams) {
    const pars = {
      pageNumber: queryParams.pageNumber,
      username: queryParams.username,
      toDate: queryParams.toDate,
      fromDate: queryParams.fromDate, 
    };
    return instance.get(`/api/user-new-deposit-report/index`, {
      headers: authHeader(),
      params: pars
    });
  }

}

export default new MarketingService();
