import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import i18n from "@/libs/i18n";

export default function usePostsList() {
  const refAgencyRadioListTable = ref(null);

  const tableColumns = [
    { key: "index", label: "#" },
    // { key: "balance_type_id", label: i18n.t("Balance Type Id") },
    // { key: "level_id", label: i18n.t("Level Id") },
    // { key: "password", label: i18n.t("Password") },
    { key: "ratio", label: i18n.t("Ratio") },
    { key: "third_partner", label: i18n.t("Third Partner") },
    { key: "level", label: i18n.t("Level") },
    { key: "created_by", label: i18n.t("Created by") },
    { key: "created_at", label: i18n.t("Created at") },
    { key: "updated_at", label: i18n.t("Updated at") },
    { key: "updated_by", label: i18n.t("Updated by") },
    // { key: "action", label: i18n.t("Actions") },
  ];
  const perPage = ref(25);
  const totalAgencyRadio = ref(0);
  const currentPage = ref(1);

  const refetchData = () => {
    refAgencyRadioListTable.value.refresh();
  };

  watch([currentPage], () => {
    refetchData();
  });

  const fetchAgencyRadio = (ctx, callback) => {
    store
      .dispatch("marketing/fetchAgencyRadio", {
        page: currentPage.value,    
      })
      .then((response) => {
        if (response.data.code == "00") {
          callback(response.data.data);
          totalAgencyRadio.value = response.data.count;
          perPage.value =
            currentPage.value == 1 ? response.data.data.length : 25;
        }
      });
  };

  const dataMeta = computed(() => {
    const localItemsCount = refAgencyRadioListTable.value
      ? refAgencyRadioListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAgencyRadio.value,
    };
  });

  const resolveStatus = (status) => {
    if (status === 1) return { label: i18n.t("Active"), variant: "success" };
    if (status === 2) return { label: i18n.t("Inactive"), variant: "danger" };
  };

  return {
    fetchAgencyRadio,
    tableColumns,
    perPage,
    currentPage,
    totalAgencyRadio,
    dataMeta,
    refAgencyRadioListTable,
    refetchData,
    resolveStatus,
  };
}
