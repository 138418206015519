<template>
    <div>
      <b-card no-body class="mb-0">
        <b-table
          ref="refUserUpgradeLevelListTable"
          class="position-relative table-white-space"
          :items="upgradeLevelList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
  
          <template #cell(username)="data">
            <div class="cursor-pointer text-white">
              {{ data.item.user.username }}
            </div>
          </template>
  
          <template #cell(level_before)="data">
            <div class="cursor-pointer text-white">
              {{ data.item.level_before.name_en }}/{{ data.item.rank_before.name }}
            </div>
          </template>

          <template #cell(level_after)="data">
            <div class="cursor-pointer text-white">
              {{ data.item.level_after.name_en }}/{{ data.item.rank_after.name }}
            </div>
          </template>
  
          <template #cell(status)="data">
            <div :class="`text-capitalize align-items-center d-flex`">
              <span :class="`dot bg-${resolveStatus(data.item.status).variant}`" />{{ resolveStatus(data.item.status).label }}
            </div>
          </template>
  
          <template #cell(bet)="data">
            {{ numberFormat(data.item.bet / 1000) }}
          </template>

          <template #cell(deposit)="data">
            {{ numberFormat(data.item.deposit / 1000) }}
          </template>
  
          <template #cell(updated_at)="data">
            {{
              data.item.updated_at
                ? formatDateTime(data.item.updated_at).replace(".000000Z", "")
                : ""
            }}
          </template>
        </b-table>
  
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalUpgradeLevel"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    VBTooltip,
  } from "bootstrap-vue";
  import store from "@/store";
  import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
  import { numberFormat, formatDateTime } from "@core/utils/filter";
  import useAgencyRadioList from "@/views/marketing/useAgencyRadioList";
  import marketingStoreModule from "@/views/marketing/marketingStoreModule";
  import baseUrl from "@/libs/base-url";
  import i18n from "@/libs/i18n";
  
  export default {
    components: {
      // AddAgencyRadioModal: () => import("./AddAgencyRadioModal.vue"),
      // UpdateAgencyRadioModal: () => import('./UpdateAgencyRadioModal.vue'),
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BImg,
    },
    directives: {
      "b-tooltip": VBTooltip,
    },
    data() {
      return {
        agencyRadioDetail: null,
      };
    },
    methods: {},
    setup() {
      const MARKETING_MODULE_NAME = "marketing";
      if (!store.hasModule(MARKETING_MODULE_NAME))
        store.registerModule(MARKETING_MODULE_NAME, marketingStoreModule);
      onUnmounted(() => {
        if (store.hasModule(MARKETING_MODULE_NAME))
          store.unregisterModule(MARKETING_MODULE_NAME);
      });

      const refUserUpgradeLevelListTable = ref(null);
      const upgradeLevelList = ref([]);

      const tableColumns = [
        { key: "index", label: "#" },
        { key: "username", label: "Username" },
        { key: "bet", label: "Bet" },
        { key: "deposit", label: "Deposit" },
        { key: "level_before", label: "Level Before" },
        { key: "level_after", label: "Level After" },
        // { key: "rank_before", label: "Rank Before" },
        // { key: "rank_after", label: "Rank After" },
        { key: "status", label: "Status" },
        { key: "reason", label: "Reason" },
        { key: "updated_at", label:"Updated at" },
        { key: "updated_by", label: "Updated by" },
        // { key: "action", label: i18n.t("Actions") },
      ];
      const perPage = ref(25);
      const totalUpgradeLevel = ref(0);
      const currentPage = ref(1);

      const refetchData = () => {
        refUserUpgradeLevelListTable.value.refresh();
      };

      watch([currentPage], () => {
        refetchData();
        getUserUpgradeLevel();
      });

      const getUserUpgradeLevel = (ctx, callback) => {
        store
          .dispatch("marketing/fetchUserUpgradeLevel", {
            pageNumber: currentPage.value,    
          })
          .then((response) => {
            if (response.data.code == "00") {
              upgradeLevelList.value = response.data.data;
              totalUpgradeLevel.value = response.data.count;
              perPage.value =
                currentPage.value == 1 ? response.data.data.length : 25;
            }
          });
      };

      getUserUpgradeLevel();

      const dataMeta = computed(() => {
        const localItemsCount = refUserUpgradeLevelListTable.value
          ? refUserUpgradeLevelListTable.value.localItems.length
          : 0;
        return {
          from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
          to: perPage.value * (currentPage.value - 1) + localItemsCount,
          of: totalUpgradeLevel.value,
        };
      });

      const resolveStatus = (status) => {
        if (status === 1) return { label: "Pending", variant: "warn" };
        if (status === 2) return { label: "Sucess", variant: "success" };
        if (status === 2) return { label: "Reject", variant: "danger" };
      };
  
      return {
        tableColumns,
        perPage,
        currentPage,
        totalUpgradeLevel,
        dataMeta,
        refUserUpgradeLevelListTable,
        refetchData,
        numberFormat,
        formatDateTime,
        baseUrl,
        upgradeLevelList,
        resolveStatus
      };
    },
  };
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-sweetalert.scss";
  </style>